@use '@thrivetrm/ui/stylesheets/variables';
@use '~assets/stylesheets/variables/bootstrap-overrides';

//
// Buttons
// --------------------------------------------------

// Base styles
// --------------------------------------------------

.btn {
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border: 1px solid transparent;
  border-radius: $btn-border-radius-base;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-size: variables.$font-size-base;
  font-weight: variables.$font-weight-bold;
  line-height: variables.$space-16;
  margin-bottom: 0; // For input.btn
  padding: variables.$space-12 variables.$space-24;
  text-align: center;
  touch-action: manipulation;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;

  + .btn {
    margin-left: variables.$space-12;
  }

  &:focus,
  &:active,
  &:active:focus {
    outline: none !important;
  }

  &,
  &:active,
  &.active {
    &:focus,
    &.focus {
      @include tab-focus;
    }
  }

  &:hover,
  &:focus,
  &.focus {
    color: $btn-default-color;
    text-decoration: none;
  }

  &:active,
  &.active {
    background-image: none;
    box-shadow: inset 0 3px 5px rgba(variables.$black-shadow, 0.125);
    outline: 0;
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    box-shadow: none;
    cursor: $cursor-disabled;
    opacity: 0.65;
  }
}

a.btn {
  &.disabled,
  fieldset[disabled] & {
    pointer-events: none; // Future-proof disabling of clicks on `<a>` elements
  }
}

// Alternate buttons
// --------------------------------------------------

.btn-default {
  @include button-variant(
    $btn-default-color,
    $btn-default-bg,
    $btn-default-border
  );
  box-shadow: 0 1px 2px 0 rgba(variables.$black-shadow, 0.1);
}
.btn-primary {
  @include button-variant(
    $btn-primary-color,
    $btn-primary-bg,
    $btn-primary-border
  );
}
// Success appears as green
.btn-success {
  @include button-variant(
    $btn-success-color,
    $btn-success-bg,
    $btn-success-border
  );
}
// Info appears as blue-green
.btn-info {
  @include button-variant($btn-info-color, $btn-info-bg, $btn-info-border);
}
// Warning appears as orange
.btn-warning {
  @include button-variant(
    $btn-warning-color,
    $btn-warning-bg,
    $btn-warning-border
  );
}
// Danger and error appear as red
.btn-danger {
  @include button-variant(
    $btn-danger-color,
    $btn-danger-bg,
    $btn-danger-border
  );
}

// Link buttons
// -------------------------

// Make a button look and behave like a link
.btn-link {
  border-radius: 0;
  color: variables.$link-color;
  cursor: pointer;
  font-weight: variables.$font-weight-normal;

  &,
  &:active,
  &.active,
  &[disabled],
  fieldset[disabled] & {
    background-color: transparent;
    box-shadow: none;
  }
  &,
  &:hover,
  &:focus,
  &:active {
    border-color: transparent;
  }
  &:hover,
  &:focus {
    background-color: transparent;
    color: variables.$link-hover-color;
    text-decoration: bootstrap-overrides.$link-hover-decoration;
  }
  &[disabled],
  fieldset[disabled] & {
    cursor: default;
    &:hover,
    &:focus {
      color: $btn-link-disabled-color;
      text-decoration: none;
    }
  }
}

// Button Sizes
// --------------------------------------------------

.btn-lg {
  // line-height: ensure even-numbered height of button next to large input
  border-radius: $btn-border-radius-base;
  font-size: variables.$font-size-large;
  line-height: $line-height-large;
  padding: variables.$space-12 variables.$space-24;
}
.btn-sm {
  // line-height: ensure proper height of button next to small input
  border-radius: $btn-border-radius-base;
  font-size: variables.$font-size-small;
  line-height: $line-height-small;
  padding: variables.$space-8 variables.$space-16;
}
.btn-xs {
  border-radius: $btn-border-radius-base;
  font-size: variables.$font-size-small;
  line-height: $line-height-small;
  padding: $padding-xs-vertical $padding-xs-horizontal;
}

// Block button
// --------------------------------------------------

.btn-block {
  display: block;
  width: 100%;
}

// Vertically space out multiple block buttons
.btn-block + .btn-block {
  margin-top: 5px;
}

// Specificity overrides
input[type='submit'],
input[type='reset'],
input[type='button'] {
  &.btn-block {
    width: 100%;
  }
}
