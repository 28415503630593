// Disabling `no-duplicate-selectors` as it's, well, wrong about `.#{$fa-css-prefix}-pull-left` and
// `.#{$fa-css-prefix}-pull-right` being duplicated in this file (they're not).
/* stylelint-disable no-duplicate-selectors */

// Bordered & Pulled
// -------------------------

.#{$fa-css-prefix}-border {
  border: solid 0.08em $fa-border-color;
  border-radius: 0.1em;
  padding: 0.2em 0.25em 0.15em;
}

.#{$fa-css-prefix}-pull-left {
  float: left;
}
.#{$fa-css-prefix}-pull-right {
  float: right;
}

.#{$fa-css-prefix} {
  &.#{$fa-css-prefix}-pull-left {
    margin-right: 0.3em;
  }
  &.#{$fa-css-prefix}-pull-right {
    margin-left: 0.3em;
  }
}

.#{$fa-css-prefix} {
  &.pull-left {
    margin-right: 0.3em;
  }
  &.pull-right {
    margin-left: 0.3em;
  }
}
