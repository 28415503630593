@use '@thrivetrm/ui/stylesheets/variables';
//
// Input groups
// --------------------------------------------------

// Base styles
// -------------------------
.input-group {
  border-collapse: separate; // prevent input groups from inheriting border styles from table cells when placed within a table
  display: table;
  position: relative; // For dropdowns
  width: 100%;

  // Undo padding and float of grid classes
  &[class*='col-'] {
    float: none;
    padding-left: 0;
    padding-right: 0;
  }

  .form-control {
    // IE9 fubars the placeholder attribute in text inputs and the arrows on
    // select elements in input groups. To fix it, we float the input. Details:
    // https://github.com/twbs/bootstrap/issues/11561#issuecomment-28936855
    float: left;
    margin-bottom: 0;
    // Ensure that the input is always above the *appended* addon button for
    // proper border colors.
    position: relative;

    width: 100%;
    z-index: 2;

    &:focus {
      z-index: 3;
    }
  }
}

// Display as table-cell
// -------------------------
.input-group-addon,
.input-group-btn,
.input-group .form-control {
  display: table-cell;

  &:not(:first-child):not(:last-child) {
    border-radius: 0;
  }
}
// Addon and addon wrapper for buttons
.input-group-addon,
.input-group-btn {
  vertical-align: middle; // Match the inputs
  white-space: nowrap;
  // Force icon containers within inputs to render consistently as a square shape.
  // I think this width acts as a min-width since the .input-group wrapper is
  // set as display: table. Min-width breaks the layout in an unpredictable way, so
  // sticking with width here and backing away slowly.
  width: variables.$input-height-base;
}

// Text input groups
// -------------------------
.input-group-addon {
  background-color: $input-group-addon-bg;
  border: 1px solid $input-group-addon-border-color;
  border-radius: variables.$input-border-radius;
  color: variables.$input-color;
  font-size: variables.$font-size-base;
  font-weight: variables.$font-weight-normal;
  line-height: 1;
  padding: $padding-base-vertical $padding-base-horizontal;
  text-align: center;

  // Nuke default margins from checkboxes and radios to vertically center within.
  input[type='radio'],
  input[type='checkbox'] {
    margin-top: 0;
  }
}

// Reset rounded corners
.input-group .form-control:first-child,
.input-group-addon:first-child,
.input-group-btn:first-child > .btn,
.input-group-btn:first-child > .btn-group > .btn,
.input-group-btn:first-child > .dropdown-toggle,
.input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group-btn:last-child > .btn-group:not(:last-child) > .btn {
  @include border-right-radius(0);
}
.input-group-addon:first-child {
  border-right: 0;
}
.input-group .form-control:last-child,
.input-group-addon:last-child,
.input-group-btn:last-child > .btn,
.input-group-btn:last-child > .btn-group > .btn,
.input-group-btn:last-child > .dropdown-toggle,
.input-group-btn:first-child > .btn:not(:first-child),
.input-group-btn:first-child > .btn-group:not(:first-child) > .btn {
  @include border-left-radius(0);
}
.input-group-addon:last-child {
  border-left: 0;
}

// Button input groups
// -------------------------
.input-group-btn {
  // Jankily prevent input button groups from wrapping with `white-space` and
  // `font-size` in combination with `inline-block` on buttons.
  font-size: 0;
  position: relative;
  white-space: nowrap;

  // Negative margin for spacing, position for bringing hovered/focused/actived
  // element above the siblings.
  > .btn {
    position: relative;
    + .btn {
      margin-left: -1px;
    }
    // Bring the "active" button to the front
    &:hover,
    &:focus,
    &:active {
      z-index: 2;
    }
  }

  // Negative margin to only have a 1px border between the two
  &:first-child {
    > .btn,
    > .btn-group {
      margin-right: -1px;
    }
  }
  &:last-child {
    > .btn,
    > .btn-group {
      margin-left: -1px;
      z-index: 2;
    }
  }

  .input-group-icon-only-btn {
    border-radius: $btn-border-radius-base;
    font-size: variables.$font-size-base;
    line-height: variables.$space-16;
    padding: $padding-base-vertical 18px;
  }
}
