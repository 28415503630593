@use '@thrivetrm/ui/stylesheets/variables';

//
// Labels
// --------------------------------------------------

.label {
  color: variables.$text-color;
  display: inline;
  font-size: variables.$font-size-small;
  font-weight: variables.$font-weight-normal;
  line-height: 15px;
  padding: 0;
  text-align: center;
  vertical-align: baseline;
  white-space: nowrap;

  // Empty labels collapse automatically (not available in IE8)
  &:empty {
    display: none;
  }

  // Quick fix for labels in buttons
  .btn & {
    position: relative;
    top: -1px;
  }
}

// Add hover effects, but only for links
a.label {
  &:hover,
  &:focus {
    color: $label-link-hover-color;
    cursor: pointer;
    text-decoration: none;
  }
}

// Colors
// Contextual variations (linked labels get darker on :hover)

.label-default {
  @include label-variant($label-default-bg);
}

.label-primary {
  @include label-variant($label-primary-bg);
}

.label-success {
  @include label-variant($label-success-bg);
}

.label-info {
  @include label-variant($label-info-bg);
}

.label-warning {
  @include label-variant($label-warning-bg);
}

.label-danger {
  @include label-variant($label-danger-bg);
}
