@use '@thrivetrm/ui/stylesheets/variables';

//
// Pagination (multiple pages)
// --------------------------------------------------
.pagination {
  border-radius: variables.$border-radius-base;
  display: inline-block;
  margin: $line-height-computed 0;
  padding-left: 0;

  > li {
    display: inline; // Remove list-style and block-level defaults
    > a,
    > span {
      background-color: $pagination-bg;
      border: 1px solid $pagination-border;
      color: $pagination-color;
      float: left; // Collapse white-space
      line-height: variables.$line-height-base;
      margin-left: -1px;
      padding: $padding-base-vertical $padding-base-horizontal;
      position: relative;
      text-decoration: none;

      &:hover,
      &:focus {
        background-color: $pagination-hover-bg;
        border-color: $pagination-hover-border;
        color: $pagination-hover-color;
        z-index: 2;
      }
    }
    &:first-child {
      > a,
      > span {
        margin-left: 0;
        @include border-left-radius(variables.$border-radius-base);
      }
    }
    &:last-child {
      > a,
      > span {
        @include border-right-radius(variables.$border-radius-base);
      }
    }
  }

  > .active > a,
  > .active > span {
    &,
    &:hover,
    &:focus {
      background-color: $pagination-active-bg;
      border-color: $pagination-active-border;
      color: $pagination-active-color;
      cursor: default;
      z-index: 3;
    }
  }

  > .disabled {
    > span,
    > span:hover,
    > span:focus,
    > a,
    > a:hover,
    > a:focus {
      background-color: $pagination-disabled-bg;
      border-color: $pagination-disabled-border;
      color: $pagination-disabled-color;
      cursor: $cursor-disabled;
    }
  }
}

// Sizing
// --------------------------------------------------

// Large
.pagination-lg {
  @include pagination-size(
    $padding-large-vertical,
    $padding-large-horizontal,
    variables.$font-size-large,
    $line-height-large,
    variables.$border-radius-large
  );
}

// Small
.pagination-sm {
  @include pagination-size(
    $padding-small-vertical,
    $padding-small-horizontal,
    variables.$font-size-small,
    $line-height-small,
    variables.$border-radius-small
  );
}
