@use '@thrivetrm/ui/stylesheets/variables';

//
// Modals
// --------------------------------------------------

// .modal-open      - body class for killing the scroll
// .modal           - container to scroll within
// .modal-dialog    - positioning shell for the actual modal
// .modal-content   - actual modal w/ bg and corners and shit
// Kill the scroll on the body
.modal-open {
  overflow: hidden;
}

// Container that the modal scrolls within
.modal {
  backdrop-filter: blur(3px);
  bottom: 0;
  display: none;
  left: 0;

  // Prevent Chrome on Windows from adding a focus outline. For details, see
  // https://github.com/twbs/bootstrap/pull/10951.
  outline: 0;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  position: fixed;
  right: 0;
  top: 0;
  z-index: $z-index-legacy-modal;

  // When fading in the modal, animate it to slide down
  &.fade .modal-dialog {
    transform: translate(0, -25%);
    transition: transform 0.3s ease-out;
  }
  &.in .modal-dialog {
    transform: translate(0, 0);
  }
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

// Shell div to position the modal with bottom padding
.modal-dialog {
  margin: 10px;
  max-width: 100%;
  position: relative;
  width: auto;
}

// Actual modal
.modal-content {
  background-clip: padding-box;
  background-color: $modal-content-bg;
  border: 0;
  border-radius: variables.$space-8;
  box-shadow: 0 10px 18px 0 rgba(variables.$black-shadow, 0.15);
  margin: 1em;
  // Remove focus outline from opened modal
  outline: 0;
  overflow: auto;
  position: relative;
}

// Are these needed? Carried these over but not totally sure what they are used for.
.modal-container .modal,
.modal-container .modal-backdrop {
  position: absolute;
}

// Modal background
.modal-backdrop {
  background-color: rgba(variables.$gray-10, 0.3);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: $z-index-overlay;
  // Fade for backdrop
  &.fade {
    opacity: 0;
  }
  &.in {
    opacity: $modal-backdrop-opacity;
  }
}

// Modal header
// Top section of the modal w/ title and dismiss
.modal-header {
  border-bottom: 1px solid variables.$gray-30;
  margin: variables.$space-24 variables.$space-24 0 variables.$space-24;
  padding-bottom: variables.$space-16;

  // This is overriding the Bootstrap '_close.css' here
  > .close {
    background-color: variables.$white;
    background-image: url('~assets/images/close.svg');
    background-position: center;
    background-repeat: no-repeat;
    border: solid 1px variables.$gray-30;
    border-radius: 4px;
    box-shadow: 0 1px 2px 0 rgba(variables.$black-shadow, 0.1);
    height: 30px;
    opacity: 1;
    width: 30px;

    span {
      // this is the 'x' rendered by Bootstrap's modal
      display: none;
    }
    &:hover {
      background-color: variables.$gray-10;
      text-decoration: none;
    }
    &:hover,
    &:focus {
      color: variables.$gray-40;
    }
  }
  @include clearfix;
}
// Close icon
.modal-header .close {
  margin-top: -2px;
}

// Title text within header
.modal-title {
  color: variables.$gray-60;
  // Not all modal-title elements are h1 or h2, so enforce serif font
  font-family: variables.$font-family-serif;
  font-size: variables.$space-24;
  font-weight: variables.$font-weight-bold;
  line-height: $modal-title-line-height;
  margin: 0;
  // Add left padding equal to the close button width (rendered on the right),
  // so that the title is perfectly centered.
  padding-left: variables.$space-32;
  text-align: center;
}

// Modal body
// Where all modal content resides (sibling of .modal-header and .modal-footer)
.modal-body {
  padding: variables.$space-24;
  position: relative;
}

// Footer (for actions)
.modal-footer {
  align-items: center;
  background-color: variables.$gray-10;
  border-bottom-left-radius: variables.$space-8;
  border-bottom-right-radius: variables.$space-8;
  display: flex;
  justify-content: flex-end;
  min-height: 72px;
  padding: 10px variables.$space-24;
}

// Measure scrollbar width for padding body during modal show/hide
.modal-scrollbar-measure {
  height: 50px;
  overflow: scroll;
  position: absolute;
  top: -9999px;
  width: 50px;
}

// Scale up the modal
@media (min-width: $screen-sm-min) {
  // Automatically set modal's width for larger viewports
  .modal-dialog {
    margin: 30px auto;
    width: $modal-md;
  }
  .modal-content {
    box-shadow: 0 10px 18px 0 rgba(variables.$black-shadow, 0.15);
  }

  // Modal sizes
  .modal-sm {
    width: $modal-sm;
  }
}

@media (min-width: $screen-md-min) {
  .modal-lg {
    width: $modal-lg;
  }
}

.modal-xl {
  @media (min-width: $screen-sm) {
    width: 768px;
  }

  @media (min-width: $screen-md) {
    width: 992px;
  }

  @media (min-width: $screen-lg) {
    width: 1200px;
  }
}
