@use '@thrivetrm/ui/stylesheets/variables';

// List Groups

@mixin list-group-item-variant($state, $background, $color) {
  .list-group-item-#{$state} {
    background-color: $background;
    color: $color;

    // [converter] extracted a&, button& to a.list-group-item-#{$state}, button.list-group-item-#{$state}
  }

  a.list-group-item-#{$state},
  button.list-group-item-#{$state} {
    color: $color;

    .list-group-item-heading {
      color: inherit;
    }

    &:hover,
    &:focus {
      background-color: darken($background, 5%);
      color: $color;
    }
    &.active,
    &.active:hover,
    &.active:focus {
      background-color: $color;
      border-color: $color;
      color: variables.$white;
    }
  }
}
