@use '@thrivetrm/ui/stylesheets/variables';

//
// Progress bars
// --------------------------------------------------

// Bar animations
// -------------------------

// Spec and IE10+
@keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}

// Bar itself
// -------------------------

// Outer container
.progress {
  background-color: $progress-bg;
  border-radius: $progress-border-radius;
  box-shadow: inset 0 1px 2px rgba(variables.$black-shadow, 0.1);
  height: $line-height-computed;
  margin-bottom: $line-height-computed;
  overflow: hidden;
}

// Bar of progress
.progress-bar {
  background-color: $progress-bar-bg;
  box-shadow: inset 0 -1px 0 rgba(variables.$black-shadow, 0.15);
  color: $progress-bar-color;
  float: left;
  font-size: variables.$font-size-small;
  height: 100%;
  line-height: $line-height-computed;
  text-align: center;
  transition: width 0.6s ease;
  width: 0%;
}

// Striped bars
//
// `.progress-striped .progress-bar` is deprecated as of v3.2.0 in favor of the
// `.progress-bar-striped` class, which you just add to an existing
// `.progress-bar`.
.progress-striped .progress-bar,
.progress-bar-striped {
  @include gradient-striped;
  background-size: 40px 40px;
}

// Call animation for the active one
//
// `.progress.active .progress-bar` is deprecated as of v3.2.0 in favor of the
// `.progress-bar.active` approach.
.progress.active .progress-bar,
.progress-bar.active {
  animation: progress-bar-stripes 2s linear infinite;
}

// Variations
// -------------------------

.progress-bar-success {
  @include progress-bar-variant($progress-bar-success-bg);
}

.progress-bar-info {
  @include progress-bar-variant($progress-bar-info-bg);
}

.progress-bar-warning {
  @include progress-bar-variant($progress-bar-warning-bg);
}

.progress-bar-danger {
  @include progress-bar-variant($progress-bar-danger-bg);
}
