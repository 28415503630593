// Disabling `sh-waqar/declaration-use-variable` to prevent it from complaining about using
// font-weight and font-family w/o a variable.
/* stylelint-disable sh-waqar/declaration-use-variable */

/* FONT PATH
 * -------------------------- */

@font-face {
  font-family: 'FontAwesome';
  font-style: normal;
  font-weight: normal;
  src: url('~assets/vendor/font-awesome/fonts/fontawesome-webfont.eot')
      format('embedded-opentype'),
    url('~assets/vendor/font-awesome/fonts/fontawesome-webfont.woff2')
      format('woff2'),
    url('~assets/vendor/font-awesome/fonts/fontawesome-webfont.woff')
      format('woff'),
    url('~assets/vendor/font-awesome/fonts/fontawesome-webfont.ttf')
      format('truetype');
}
