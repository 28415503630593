@use '@thrivetrm/ui/stylesheets/variables';

@mixin reset-text() {
  font-family: variables.$font-family-base;
  // We deliberately do NOT reset font-size.
  font-style: normal;
  font-weight: variables.$font-weight-normal;
  letter-spacing: normal;
  line-break: auto;
  line-height: variables.$line-height-base;
  text-align: left;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
}
