// NOTE: this file is a highly trimmed-down version of Bootstrap's v4.1 Grid
// https://github.com/twbs/bootstrap/blob/master/scss/_grid.scss
//
// We chose to retain Bootstrap grids under the hood to avoid having two
// separate grid frameworks (Bootstrap v3 float-based grids in Rails, and
// some other flexbox-based grids in React). We've ejected the Boostrap
// library (its source code lives and is edited inside of our codebase) and
// desparately wanted to move away from float-based layouts, so we chose
// to upgrade to Bootstrap V4's grid CSS and wrap it with our own custom
// React component. With this approach, all existing Grid consumers across
// all portions of the app will continue to work, but will be brought into
// the modern age. All React consumers moving forward should always use the
// <Grid> component, and never author these classnames directly in JSX.
//
// Notable differences from Bootstrap v4.1 Source Code:
// * Waaaaaay less programmatic CSS generation. Bootstrap internally uses a
//   TON of mixins and functions. Here we've favored mild repetition in
//   exchange for a drastic decrease in complexity
// * We don't support "pull" or "col-auto" (shrinking columns), since we have
//   no use cases for these features
// * We have removed `position: relative` on columns. This style was needed for
//   Bootstrap v3, but was accidentally retained in v4. It is scheduled for
//   removal in v5. We're turning this off because it's bad practice to have
//   all columns always positioned relative.
//   See: https://github.com/twbs/bootstrap/issues/25254

@mixin gridColumns($size: null) {
  // If no size is provided, we want class names like "col-1", "col-2"
  // If a size is provided, e.g. "sm", we want class names like "col-sm-1", "col-sm-2"
  @if $size != null {
    $size: -#{$size};
  }

  .col#{$size} {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col#{$size}-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col#{$size}-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col#{$size}-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col#{$size}-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col#{$size}-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col#{$size}-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col#{$size}-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col#{$size}-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col#{$size}-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col#{$size}-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col#{$size}-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col#{$size}-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@mixin gridOffsets($size: null) {
  // If no size is provided, we want class names like "offset-1", "offset-2"
  // If a size is provided, e.g. "sm", we want class names like "offset-sm-1", "offset-sm-2"
  @if $size != null {
    $size: -#{$size};
  }

  .offset#{$size}-0 {
    margin-left: 0;
  }
  .offset#{$size}-1 {
    margin-left: 8.33333%;
  }
  .offset#{$size}-2 {
    margin-left: 16.66667%;
  }
  .offset#{$size}-3 {
    margin-left: 25%;
  }
  .offset#{$size}-4 {
    margin-left: 33.33333%;
  }
  .offset#{$size}-5 {
    margin-left: 41.66667%;
  }
  .offset#{$size}-6 {
    margin-left: 50%;
  }
  .offset#{$size}-7 {
    margin-left: 58.33333%;
  }
  .offset#{$size}-8 {
    margin-left: 66.66667%;
  }
  .offset#{$size}-9 {
    margin-left: 75%;
  }
  .offset#{$size}-10 {
    margin-left: 83.33333%;
  }
  .offset#{$size}-11 {
    margin-left: 91.66667%;
  }
}

.container,
.container-fluid {
  margin-left: auto;
  margin-right: auto;
  padding-left: calc($grid-gutter-width / 2);
  padding-right: calc($grid-gutter-width / 2);
  width: 100%;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: calc($grid-gutter-width / -2);
  margin-right: calc($grid-gutter-width / -2);
}

.no-gutters {
  margin-left: 0;
  margin-right: 0;

  > .col,
  > [class*='col-'] {
    padding-left: 0;
    padding-right: 0;
  }
}

.col,
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col-sm,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-md,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-lg,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-xl,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12 {
  padding-left: calc($grid-gutter-width / 2);
  padding-right: calc($grid-gutter-width / 2);
  width: 100%;
}

// X-SMALL BREAKPOINT
// x-small is the default if no breakpoint declared, e.g. a class of '.col-6'
@include gridColumns();
@include gridOffsets();

// SMALL BREAKPOINT
@media (min-width: $screen-sm-min) {
  .container {
    max-width: $container-sm;
  }
  @include gridColumns(sm);
  @include gridOffsets(sm);
}

// MEDIUM BREAKPOINT
@media (min-width: $screen-md-min) {
  .container {
    max-width: $container-md;
  }
  @include gridColumns(md);
  @include gridOffsets(md);
}

// LARGE BREAKPOINT
@media (min-width: $screen-lg-min) {
  .container {
    max-width: $container-lg;
  }
  @include gridColumns(lg);
  @include gridOffsets(lg);
}

// X-LARGE BREAKPOINT
@media (min-width: $screen-xl-min) {
  // A `.container` max-width is not specified for XL because it uses the same
  // max-width as the large breakpoint - this is what causes the UI to be
  // centered in the page when using non-fluid containers.
  @include gridColumns(xl);
  @include gridOffsets(xl);
}
