@use '@thrivetrm/ui/stylesheets/variables';
// Form validation states
//
// Used in forms.less to generate the form validation CSS for warnings, errors,
// and successes.

@mixin form-control-validation(
  $text-color: #555,
  $border-color: #ccc,
  $background-color: #f5f5f5
) {
  // Color the label and help text
  .help-block,
  .radio,
  .checkbox,
  .radio-inline,
  .checkbox-inline,
  &.radio label,
  &.checkbox label,
  &.radio-inline label,
  &.checkbox-inline label {
    color: $text-color;
  }
  // Set the border and box shadow on specific inputs to match
  .form-control {
    border-color: $border-color;
    box-shadow: inset 0 1px 1px rgba(variables.$black-shadow, 0.075);
    // Redeclare so transitions work
    &:focus {
      border-color: darken($border-color, 10%);
      box-shadow: inset 0 1px 1px rgba(variables.$black-shadow, 0.075),
        0 0 6px lighten($border-color, 20%);
    }
  }
  // Set validation states also for addons
  .input-group-addon {
    background-color: $background-color;
    border-color: $border-color;
    color: $text-color;
  }
  // Optional feedback icon
  .form-control-feedback {
    color: $text-color;
  }
}
