@use '@thrivetrm/ui/stylesheets/variables';

//
// Code (inline and block)
// --------------------------------------------------

// Inline and block code styles
code,
kbd,
pre,
samp {
  font-family: $font-family-monospace;
}

// Inline code
code {
  background-color: $code-bg;
  border-radius: variables.$border-radius-base;
  color: $code-color;
  font-size: 90%;
  padding: 2px 4px;
}

// User input typically entered via keyboard
kbd {
  background-color: $kbd-bg;
  border-radius: variables.$border-radius-small;
  box-shadow: inset 0 -1px 0 rgba(variables.$black-shadow, 0.25);
  color: $kbd-color;
  font-size: 90%;
  padding: 2px 4px;

  kbd {
    box-shadow: none;
    font-size: 100%;
    font-weight: variables.$font-weight-bold;
    padding: 0;
  }
}

// Blocks of code
pre {
  background-color: $pre-bg;
  border: 1px solid $pre-border-color;
  border-radius: variables.$border-radius-base;
  color: $pre-color;
  display: block;
  font-size: (variables.$font-size-base - 1); // 14px to 13px
  line-height: variables.$line-height-base;
  margin: 0 0 calc($line-height-computed / 2);
  padding: calc(($line-height-computed - 1px) / 2);
  word-break: break-all;
  word-wrap: break-word;

  // Account for some code outputs that place code tags in pre tags
  code {
    background-color: transparent;
    border-radius: 0;
    color: inherit;
    font-size: inherit;
    padding: 0;
    white-space: pre-wrap;
  }
}

// Enable scrollable blocks of code
.pre-scrollable {
  max-height: $pre-scrollable-max-height;
  overflow-y: scroll;
}
