@use '@thrivetrm/ui/stylesheets/variables';

//
// Close icons
// --------------------------------------------------

.close {
  color: $close-color;
  float: right;
  font-size: (variables.$font-size-base * 1.5);
  font-weight: $close-font-weight;
  line-height: 1;
  opacity: 0.2;
  text-shadow: $close-text-shadow;

  &:hover,
  &:focus {
    color: $close-color;
    cursor: pointer;
    opacity: 0.5;
    text-decoration: none;
  }

  // [converter] extracted button& to button.close
}

// Additional properties for button version
// iOS requires the button element instead of an anchor tag.
// If you want the anchor version, it requires `href="#"`.
// See https://developer.mozilla.org/en-US/docs/Web/Events/click#Safari_Mobile
button.close {
  appearance: none;
  background: transparent;
  border: 0;
  cursor: pointer;
  padding: 0;
}
