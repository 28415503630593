@use '@thrivetrm/ui/stylesheets/variables';

/**
 * The datepicker styles use rems by default, but bootstrap
 * resets the font-size to 10px, which makes the datepicker super
 * small. It would be best/easiest to just reset the base html font size,
 * but that messes with lots of other things. So for now, this overrides those
 * styles just for the datepicker. It kinda sucks because if react-datepicker
 * updates/changes their styles, it could bork this. So if stuff starts
 * looking weird, compare this to, and perhaps replace everythign except
 * the variables completely (although note that the variable names have
 * been prefixed as to avoid collisions with other variable names.)
 * https://github.com/Hacker0x01/react-datepicker/blob/master/src/stylesheets/datepicker.scss
 *
 * IMPORTANT! For easier maintenance, add any custom code/overrides below
 * under the section labeled "Thrive-specific styles"! When react-datepicker changes styles,
 * it will be much easier to determine which styles we have customized.
 */

$datepicker__background-color: #f0f0f0;
$datepicker__border-color: #aeaeae;
$datepicker__selected-color: #216ba5;
$datepicker__highlighted-color: #3dcc4a;
$datepicker__muted-color: #cccccc;
$datepicker__text-color: variables.$gray-60;

$datepicker__font-size: 0.9em;
$datepicker__border-radius: 0.3em;
$datepicker__item-size: 1.7em;
$datepicker__day-margin: 0.166em;
$datepicker__margin: 0.4em;
$datepicker__navigation-size: 0.45em;
$datepicker__triangle-size: 10px;

$datepicker__navigation-disabled-color: lighten(
  $datepicker__muted-color,
  10%
) !default;

%datepicker-triangle-arrow {
  margin-left: -$datepicker__triangle-size;
  position: absolute;

  &,
  &::before {
    border: $datepicker__triangle-size solid transparent;
    box-sizing: content-box;

    height: 0;
    position: absolute;
    width: 1px;
  }

  &::before {
    border-bottom-color: $datepicker__border-color;
    border-width: $datepicker__triangle-size;
    content: '';

    left: -$datepicker__triangle-size;
    z-index: -1;
  }
}

%datepicker-triangle-arrow-up {
  @extend %datepicker-triangle-arrow;
  margin-top: -$datepicker__triangle-size;

  top: 0;

  &,
  &::before {
    border-bottom-color: $datepicker__background-color;
    border-top: none;
  }

  &::before {
    border-bottom-color: $datepicker__border-color;
    top: -1px;
  }
}

%datepicker-triangle-arrow-down {
  @extend %datepicker-triangle-arrow;

  bottom: 0;
  margin-bottom: -$datepicker__triangle-size;

  &,
  &::before {
    border-bottom: none;
    border-top-color: variables.$white;
  }

  &::before {
    border-top-color: $datepicker__border-color;
    bottom: -1px;
  }
}

.react-datepicker-wrapper {
  display: inline-block;
}

// There's a DateInput in @thrivetrm/ui and the above ruleset clobbers the
// ability to make use of a full-width (100%) input component.
.DateInput {
  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    display: initial;
  }
}

.react-datepicker {
  background-color: variables.$white;
  border: 1px solid $datepicker__border-color;
  border-radius: $datepicker__border-radius;
  color: $datepicker__text-color;
  display: inline-block;
  font-family: variables.$font-family-sans-serif;
  font-size: $datepicker__font-size;
  position: relative;
}

.react-datepicker--time-only {
  .react-datepicker__time-container {
    border-left: 0;
  }
  .react-datepicker__time {
    border-radius: $datepicker__border-radius;
  }
  .react-datepicker__time-box {
    border-radius: $datepicker__border-radius;
  }
}

.react-datepicker__triangle {
  left: 50px;
  position: absolute;
}

.react-datepicker-popper {
  // Z-index must be greater than 5000 so that datepicker popovers show
  // correctly when rendered inside a bootstrap modal, which has a z-index of
  // 5000 itself.
  z-index: $z-index-legacy-modal + 1;

  &[data-placement^='bottom'] {
    margin-top: $datepicker__triangle-size + 2px;

    .react-datepicker__triangle {
      @extend %datepicker-triangle-arrow-up;
    }
  }

  &[data-placement^='top'] {
    margin-bottom: $datepicker__triangle-size - 2px;

    .react-datepicker__triangle {
      @extend %datepicker-triangle-arrow-down;
    }
  }

  &[data-placement^='right'] {
    margin-left: $datepicker__triangle-size;

    .react-datepicker__triangle {
      left: auto;
      right: 42px;
    }
  }

  &[data-placement^='left'] {
    margin-right: $datepicker__triangle-size;

    .react-datepicker__triangle {
      left: 42px;
      right: auto;
    }
  }
}

.react-datepicker__header {
  background-color: $datepicker__background-color;
  border-bottom: 1px solid $datepicker__border-color;
  border-top-left-radius: $datepicker__border-radius;
  border-top-right-radius: $datepicker__border-radius;
  padding-top: 8px;
  position: relative;
  text-align: center;

  &--time {
    padding-bottom: 8px;
    padding-left: 5px;
    padding-right: 5px;
  }
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  display: inline-block;
  margin: 0 2px;
}

.react-datepicker__current-month,
.react-datepicker-time__header {
  color: variables.$gray-60;
  font-size: $datepicker__font-size * 1.18;
  font-weight: variables.$font-weight-bold;
  margin-top: 0;
}

.react-datepicker-time__header {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.react-datepicker__navigation {
  background: none;
  border: $datepicker__navigation-size solid transparent;
  cursor: pointer;
  height: 10px;
  line-height: $datepicker__item-size;
  overflow: hidden;
  padding: 0;
  position: absolute;
  text-align: center;
  text-indent: -999em;
  top: 10px;
  width: 10px;
  z-index: 1;

  &--previous {
    border-right-color: $datepicker__muted-color;
    left: 10px;

    &:hover {
      border-right-color: darken($datepicker__muted-color, 10%);
    }

    &--disabled,
    &--disabled:hover {
      border-right-color: $datepicker__navigation-disabled-color;
      cursor: default;
    }
  }

  &--next {
    border-left-color: $datepicker__muted-color;
    right: 10px;
    &--with-time:not(&--with-today-button) {
      right: 80px;
    }

    &:hover {
      border-left-color: darken($datepicker__muted-color, 10%);
    }

    &--disabled,
    &--disabled:hover {
      border-left-color: $datepicker__navigation-disabled-color;
      cursor: default;
    }
  }

  &--years {
    display: block;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    top: 0;

    &-previous {
      border-top-color: $datepicker__muted-color;
      top: 4px;

      &:hover {
        border-top-color: darken($datepicker__muted-color, 10%);
      }
    }

    &-upcoming {
      border-bottom-color: $datepicker__muted-color;
      top: -4px;

      &:hover {
        border-bottom-color: darken($datepicker__muted-color, 10%);
      }
    }
  }
}

.react-datepicker__month-container {
  display: inline;
  float: left;
}

.react-datepicker__month {
  margin: $datepicker__margin;
  text-align: center;
}

.react-datepicker__week-number {
  color: $datepicker__muted-color;
  display: inline-block;
  line-height: $datepicker__item-size;
  margin: $datepicker__day-margin;
  text-align: center;
  width: $datepicker__item-size;
  &.react-datepicker__week-number--clickable {
    cursor: pointer;
    &:hover {
      background-color: $datepicker__background-color;
      border-radius: $datepicker__border-radius;
    }
  }
}

.react-datepicker__time-container {
  border-left: 1px solid $datepicker__border-color;
  float: right;
  width: 70px;

  &--with-today-button {
    border: 1px solid $datepicker__border-color;
    border-radius: $datepicker__border-radius;
    display: inline;
    position: absolute;
    right: -72px;
    top: 0;
  }

  .react-datepicker__time {
    background: $datepicker__background-color;
    position: relative;
  }

  .react-datepicker__time-box {
    margin: 0 auto;
    overflow-x: hidden;
    text-align: center;
    width: 70px;
  }

  .react-datepicker__time-list {
    box-sizing: content-box;
    height: calc(195px + (#{$datepicker__item-size} / 2));
    list-style: none;
    margin: 0;
    overflow-y: scroll;
    padding-left: 0px;
    padding-right: 0px;
    width: 100%;
  }

  .react-datepicker__time-list-item {
    height: 30px;
    padding: 5px 10px;
    &:hover {
      background-color: $datepicker__background-color;
      cursor: pointer;
    }
    &--selected {
      background-color: $datepicker__selected-color;
      color: variables.$white;
      font-weight: variables.$font-weight-bold;
      &:hover {
        background-color: $datepicker__selected-color;
      }
    }
    &--disabled {
      color: $datepicker__muted-color;

      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }
  }
}

.react-datepicker__day-names,
.react-datepicker__week {
  white-space: nowrap;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: $datepicker__text-color;
  display: inline-block;
  line-height: $datepicker__item-size;
  margin: $datepicker__day-margin;
  text-align: center;
  width: $datepicker__item-size;
}

.react-datepicker__day {
  cursor: pointer;

  &:hover {
    background-color: $datepicker__background-color;
    border-radius: $datepicker__border-radius;
  }

  &--today {
    font-weight: variables.$font-weight-bold;
  }

  &--highlighted {
    background-color: $datepicker__highlighted-color;
    border-radius: $datepicker__border-radius;
    color: variables.$white;

    &:hover {
      background-color: darken($datepicker__highlighted-color, 5%);
    }

    &-custom-1 {
      color: magenta;
    }

    &-custom-2 {
      color: green;
    }
  }

  &--selected,
  &--in-selecting-range,
  &--in-range {
    background-color: $datepicker__selected-color;
    border-radius: $datepicker__border-radius;
    color: variables.$white;

    &:hover {
      background-color: darken($datepicker__selected-color, 5%);
    }
  }

  &--keyboard-selected {
    background-color: lighten($datepicker__selected-color, 10%);
    border-radius: $datepicker__border-radius;
    color: variables.$white;

    &:hover {
      background-color: darken($datepicker__selected-color, 5%);
    }
  }

  &--in-selecting-range:not(&--in-range) {
    background-color: rgba($datepicker__selected-color, 0.5);
  }

  &--in-range:not(&--in-selecting-range) {
    .react-datepicker__month--selecting-range & {
      background-color: $datepicker__background-color;
      color: $datepicker__text-color;
    }
  }

  &--disabled {
    color: $datepicker__muted-color;
    cursor: default;

    &:hover {
      background-color: transparent;
    }
  }
}

.react-datepicker__input-container {
  display: inline-block;
  position: relative;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
  border: 1px solid transparent;
  border-radius: $datepicker__border-radius;

  &:hover {
    cursor: pointer;

    .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view--down-arrow {
      border-top-color: darken($datepicker__muted-color, 10%);
    }
  }

  &--down-arrow {
    @extend %datepicker-triangle-arrow-down;
    border-top-color: $datepicker__muted-color;
    border-width: $datepicker__navigation-size;
    float: right;
    margin-left: 20px;
    position: relative;
    top: 8px;
  }
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: $datepicker__background-color;
  border: 1px solid $datepicker__border-color;
  border-radius: $datepicker__border-radius;
  left: 25%;
  position: absolute;
  text-align: center;
  top: 30px;
  width: 50%;
  z-index: 1;

  &:hover {
    cursor: pointer;
  }

  &--scrollable {
    height: 150px;
    overflow-y: scroll;
  }
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
  display: block;
  line-height: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  &:first-of-type {
    border-top-left-radius: $datepicker__border-radius;
    border-top-right-radius: $datepicker__border-radius;
  }

  &:last-of-type {
    border-bottom-left-radius: $datepicker__border-radius;
    border-bottom-right-radius: $datepicker__border-radius;
    user-select: none;
  }

  &:hover {
    background-color: $datepicker__muted-color;

    .react-datepicker__navigation--years-upcoming {
      border-bottom-color: darken($datepicker__muted-color, 10%);
    }

    .react-datepicker__navigation--years-previous {
      border-top-color: darken($datepicker__muted-color, 10%);
    }
  }

  &--selected {
    left: 15px;
    position: absolute;
  }
}

.react-datepicker__close-icon {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: inline-block;
  height: 16px;
  outline: 0;
  padding: 0;
  position: absolute;
  right: 7px;
  top: 25%;
  vertical-align: middle;
  width: 16px;

  &::after {
    background-color: $datepicker__selected-color;
    border-radius: 50%;
    bottom: 0;
    box-sizing: border-box;
    color: variables.$white;
    content: '\00d7';
    cursor: pointer;
    font-size: 12px;
    height: 16px;
    line-height: 1;
    margin: -8px auto 0;
    padding: 2px;
    position: absolute;
    right: 0px;
    text-align: center;
    width: 16px;
  }
}

.react-datepicker__today-button {
  // Thrive-specific style
  background: inherit;
  // Thrive-specific style
  border-top: none;
  clear: left;
  cursor: pointer;
  font-weight: variables.$font-weight-bold;
  padding: 5px 0;
  text-align: center;
}

.react-datepicker__portal {
  align-items: center;
  background-color: rgba(variables.$black-shadow, 0.8);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 2147483647;

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    line-height: 3rem;
    width: 3rem;
  }

  // Resize for small screens
  @media (max-width: 400px), (max-height: 550px) {
    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
      line-height: 2rem;
      width: 2rem;
    }
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header {
    font-size: $datepicker__font-size * 1.8;
  }

  .react-datepicker__navigation {
    border: 1.8 * $datepicker__navigation-size solid transparent;
  }

  .react-datepicker__navigation--previous {
    border-right-color: $datepicker__muted-color;

    &:hover {
      border-right-color: darken($datepicker__muted-color, 10%);
    }
  }

  .react-datepicker__navigation--next {
    border-left-color: $datepicker__muted-color;

    &:hover {
      border-left-color: darken($datepicker__muted-color, 10%);
    }
  }
}

/**
 * Thrive-specific styles
 */

.react-datepicker__week {
  /** Make sure the days of any one week don't wrap */
  white-space: nowrap;
}
