@use '@thrivetrm/ui/stylesheets/variables';

/* stylelint-disable sh-waqar/declaration-use-variable */
// ^ We want to expose "font stacks" as variables, not single font names. This file
//   declares the named fonts, and we then expose them as font-stack variables
//   in _variables.scss.

/* Copyright (C) Hoefler & Co. This software is the property of Hoefler & Co. (H&Co). Your right to access and use this software is subject to the applicable License Agreement, or Terms of Service, that exists between you and H&Co. If no such agreement exists, you may not access or use this software for any purpose. This software may only be hosted at the locations specified in the applicable License Agreement or Terms of Service, and only for the purposes expressly set forth therein. You may not copy, modify,convert, create derivative works from or distribute this software in any way, or make it accessible to any third party, without first obtaining the written permission of H&Co. For more information, please visit us at http://typography.com. */

//  We have purchased self-hosting licenses for two Hoefler & Co. font families. We are
//  licensed for up to 1 million page views per month, and the licenses are currently
//  billed yearly. Management of the licenses is handled through our account on
//  https://typography.com.
//
//  We currently use
//    * Whitney ScreenSmart: a sans-serif font optimized for rendering on screens at
//      sizes between 9px and 18px
//    * Sentinel MultiPurpose: a serif font optimized for rendering in print, or on
//      screens at sizes 18px and up
//
//  Even though additional weights and styles are included in our license, we are
//  only declaring the weights and styles used by our app in order to decrease
//  payload size.

// WHITNEY
@font-face {
  font-family: 'Whitney';
  font-style: normal;
  font-weight: variables.$font-weight-normal;
  src: url('~assets/fonts/WhitneySSm-Medium_Web.woff2') format('woff2'),
    url('~assets/fonts/WhitneySSm-Medium_Web.woff') format('woff');
}

@font-face {
  font-family: 'Whitney';
  font-style: normal;
  font-weight: variables.$font-weight-bold;
  src: url('~assets/fonts/WhitneySSm-Semibold_Web.woff2') format('woff2'),
    url('~assets/fonts/WhitneySSm-Semibold_Web.woff') format('woff');
}

// SENTINEL
@font-face {
  font-family: 'Sentinel';
  font-style: normal;
  font-weight: variables.$font-weight-bold;
  src: url('~assets/fonts/Sentinel-Semibold_Web.woff2') format('woff2'),
    url('~assets/fonts/Sentinel-Semibold_Web.woff') format('woff');
}
