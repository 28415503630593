@use '@thrivetrm/ui/stylesheets/variables';

// GLOBAL TABLE STYLES
table {
  border: 1px solid variables.$gray-20;
  border-bottom: none;
  box-shadow: 0 2px 4px rgba(variables.$gray-60, 0.12);
  color: variables.$gray-60;
  width: 100%;
}

table.table--isPlain {
  box-shadow: none;
}

th,
td {
  background-color: variables.$white;
  border-bottom: 1px solid variables.$gray-20;
}

tr > th:first-child,
tr > td:first-child {
  padding-left: variables.$space-16;
}

tr > th:last-child,
tr > td:last-child {
  padding-right: variables.$space-16;
}

thead th:last-child {
  padding-right: variables.$space-32;

  .SortIndicator {
    right: variables.$space-16;
  }
}

thead {
  th,
  td {
    font-size: variables.$font-size-small;
    height: 48px;
    line-height: variables.$font-size-base;
  }

  th {
    &.th--isSortKey {
      background-color: variables.$gray-10;
    }
    &:hover.th--isSortable:not(.th--isSortKey) {
      background-color: variables.$gray-5;
    }
    &.th--isSortable {
      cursor: pointer;
      padding-right: variables.$space-32;
    }

    padding: 0 variables.$space-8;
    position: relative;
    text-align: left;
    word-wrap: break-word;

    .SortIndicator {
      position: absolute;
      right: variables.$space-8;
      top: calc(50% - 12px);
    }
  }
}

// Table Row Hover

// Apply to table for highlighting row on hover
.table--rowHighlight tbody tr:hover {
  th,
  td {
    background-color: variables.$gray-10;
  }
}

// Apply to any element in a row that should only display when a row is hovered.
.table__displayOnHover {
  opacity: 0;
  transition: opacity 150ms ease-out;
}

tbody {
  tr:hover {
    .table__displayOnHover {
      opacity: 1;
    }
  }
}

td {
  height: variables.$space-64;
  padding: variables.$space-8;
  word-wrap: break-word;
}

// Table cell (th, td) containing selection checkbox
.table__selection-cell {
  padding-right: variables.$space-8;
}

// COLUMN SIZING
.th--xsmall {
  min-width: 100px;
}
.th--small {
  min-width: 140px;
}
.th--medium {
  min-width: 180px;
}
.th--large {
  min-width: 220px;
}
.th--xlarge {
  min-width: 300px;
}

// Table modifiers
.table--isCompact {
  th,
  td {
    height: variables.$space-24;
    padding-bottom: variables.$space-8;
    padding-top: variables.$space-8;
  }
}

// --------------  LEGACY -------- //

// LEGACY - Enable legacy tables using table-index pattern to
// show which column is currently sorted on
th .sort_link {
  align-items: center;
  color: inherit;
  display: flex;
  height: 100%;
  justify-content: space-between;
  padding: 0 8px;

  .indicator {
    color: variables.$brand-primary;
  }

  &.asc,
  &.desc {
    background-color: variables.$gray-10;
  }
}

.TableHead--withoutSelection > .TableHead__row--selection {
  display: none;
}

.TableHead--withSelection > .TableHead__row--columns {
  line-height: 0;
  // Handles the toggling of the display of the standard column headers
  // or the selection header, based on whether there is currently any
  // selection or not.
  visibility: collapse;

  th {
    border: 0;
  }
}

.TableHead__row--columns a,
.TableHead__row--columns .btn-link {
  color: variables.$gray-60;
  display: block;
  // For some reason all `.btn-link` and `a` elements have a transition
  // set on them (in /stlesheets/components/_links.scss). This causes a delay
  // and some weird visual behavior when toggling between the display of the
  // standard column headers and the "selection" header.
  transition: none;
}

// Force checkboxes in tables to match the dimensions of
// v4 checkboxes so that fixed columns align when scrolling
.table__selection-cell > input[type='checkbox'] {
  height: variables.$space-16;
  width: variables.$space-16;
}

.table-index {
  .btn-move-stage.btn {
    background-color: variables.$white;
    border: 1px solid variables.$color-border;
    border-radius: variables.$border-radius-base !important;
    i {
      color: variables.$link-color;
    }
    .btn-label {
      display: none;
    }
  }

  .table .empty-table {
    color: variables.$gray-40;
    text-align: center;
  }

  // Remove when company index v2 is fully deprecated
  &#companies-list {
    .column-website {
      text-align: center;
    }
    .column-header-icon {
      text-align: center;
    }
  }
}
