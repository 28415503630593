@use '@thrivetrm/ui/stylesheets/variables';

//
// Typography
// --------------------------------------------------

// Headings
// -------------------------

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: variables.$headings-color;
  font-family: variables.$headings-font-family;
  font-weight: variables.$headings-font-weight;
  line-height: variables.$headings-line-height;
  margin-bottom: variables.$space-12;
  margin-top: variables.$space-12;

  small,
  .small {
    color: variables.$headings-small-color;
    font-weight: variables.$font-weight-normal;
    line-height: 1;
  }
}

h1,
.h1,
h2,
.h2,
h3,
.h3 {
  margin-top: variables.$space-24;

  small,
  .small {
    font-size: 65%;
  }
}
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  small,
  .small {
    font-size: 75%;
  }
}

h1,
.h1 {
  font-family: variables.$font-family-serif;
  font-size: variables.$font-size-heading-xlarge;
}
h2,
.h2 {
  font-family: variables.$font-family-serif;
  font-size: variables.$font-size-heading-large;
}
h3,
.h3 {
  font-size: variables.$font-size-heading-medium;
}
h4,
.h4 {
  font-size: variables.$font-size-heading-small;
}
h5,
.h5 {
  font-size: variables.$font-size-heading-xsmall;
}
h6,
.h6 {
  font-size: variables.$font-size-heading-xxsmall;
}

// Body text
// -------------------------

p {
  margin: 0 0 calc($line-height-computed / 2);
}

.lead {
  font-size: floor((variables.$font-size-base * 1.15));
  font-weight: variables.$font-weight-normal;
  line-height: 1.4;
  margin-bottom: $line-height-computed;

  @media (min-width: $screen-sm-min) {
    font-size: (variables.$font-size-base * 1.5);
  }
}

// Emphasis & misc
// -------------------------

// Ex: (12px small font / 14px base font) * 100% = about 85%
small,
.small {
  font-size: floor(
    calc(100% * variables.$font-size-small / variables.$font-size-base)
  );
}

mark,
.mark {
  background-color: transparent;
  border-bottom-style: dotted;
  border-bottom-width: 1px;
}

// Alignment
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-justify {
  text-align: justify;
}
.text-nowrap {
  white-space: nowrap;
}

// Transformation
.text-lowercase {
  text-transform: lowercase;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-capitalize {
  text-transform: capitalize;
}

// Contextual colors
.text-muted {
  color: $text-muted;
}

@include text-emphasis-variant('.text-primary', variables.$brand-primary);

@include text-emphasis-variant('.text-success', $state-success-text);

@include text-emphasis-variant('.text-info', $state-info-text);

@include text-emphasis-variant('.text-warning', $state-warning-text);

@include text-emphasis-variant('.text-danger', $state-danger-text);

// Contextual backgrounds
// For now we'll leave these alongside the text classes until v4 when we can
// safely shift things around (per SemVer rules).
.bg-primary {
  // Given the contrast here, this is the only class to have its color inverted
  // automatically.
  color: variables.$white;
}
@include bg-variant('.bg-primary', variables.$brand-primary);

@include bg-variant('.bg-success', $state-success-bg);

@include bg-variant('.bg-info', $state-info-bg);

@include bg-variant('.bg-warning', $state-warning-bg);

@include bg-variant('.bg-danger', $state-danger-bg);

// Lists
// -------------------------

// Unordered and Ordered lists
ul,
ol {
  margin-bottom: calc($line-height-computed / 2);
  margin-top: 0;
  ul,
  ol {
    margin-bottom: 0;
  }
}

// List options

.list-unstyled {
  list-style: none;
  padding-left: 0;
}

// Inline turns list items into inline-block
.list-inline {
  list-style: none;
  margin-left: -5px;
  padding-left: 0;

  > li {
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;
  }
}

// Description Lists
dl {
  margin-bottom: $line-height-computed;
  margin-top: 0; // Remove browser default
}
dt,
dd {
  line-height: variables.$line-height-base;
}
dt {
  font-weight: variables.$font-weight-bold;
}
dd {
  margin-left: 0; // Undo browser default
}

// Horizontal description lists
//
// Defaults to being stacked without any of the below styles applied, until the
// grid breakpoint is reached (default of ~768px).

.dl-horizontal {
  dd {
    @include clearfix; // Clear the floated `dt` if an empty `dd` is present
  }

  @media (min-width: $dl-horizontal-breakpoint) {
    dt {
      clear: left;
      float: left;
      text-align: right;
      width: ($dl-horizontal-offset - 20);
      @include text-overflow;
    }
    dd {
      margin-left: $dl-horizontal-offset;
    }
  }
}

// Misc
// -------------------------

// Abbreviations and acronyms
// Add data-* attribute to help out our tooltip plugin, per https://github.com/twbs/bootstrap/issues/5257
abbr[title],
abbr[data-original-title] {
  cursor: help;
}

.initialism {
  font-size: 90%;
  @extend .text-uppercase;
}

// Blockquotes
blockquote {
  border-left: 5px solid $blockquote-border-color;
  font-size: $blockquote-font-size;
  // margin: 0 0 $line-height-computed;
  margin: 0.5em 0 0.5em;
  padding: calc($line-height-computed / 2) $line-height-computed;

  p,
  ul,
  ol {
    &:last-child {
      margin-bottom: 0;
    }
  }

  // Note: Deprecated small and .small as of v3.1.0
  // Context: https://github.com/twbs/bootstrap/issues/11660
  footer,
  small,
  .small {
    color: $blockquote-small-color;
    display: block;
    font-size: 80%; // back to default font-size
    line-height: variables.$line-height-base;

    &:before {
      content: '\2014 \00A0'; // em dash, nbsp
    }
  }
}

// Opposite alignment of blockquote
//
// Heads up: `blockquote.pull-right` has been deprecated as of v3.1.0.
.blockquote-reverse,
blockquote.pull-right {
  border-left: 0;
  border-right: 5px solid $blockquote-border-color;
  padding-left: 0;
  padding-right: 15px;
  text-align: right;

  // Account for citation
  footer,
  small,
  .small {
    &:before {
      content: '';
    }
    &:after {
      content: '\00A0 \2014'; // nbsp, em dash
    }
  }
}

// Addresses
address {
  font-style: normal;
  line-height: variables.$line-height-base;
  margin-bottom: $line-height-computed;
}
