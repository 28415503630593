@use '@thrivetrm/ui/stylesheets/variables';

// Select2 Overrides
// ----------------------------------------

span.select2-selection__placeholder {
  // Force Thrive fonts in placeholders
  font-family: variables.$font-family-sans-serif;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: variables.$input-color;
  line-height: variables.$input-line-height;
  padding: variables.$space-8 variables.$space-12;
  // account for button padding + arrow width
  padding-right: variables.$space-12 + variables.$space-16;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__placeholder {
  color: variables.$input-color-placeholder;
}

.select2-container
  .select2-selection.select2-selection--multiple
  .select2-search.select2-search--inline
  .select2-search__field:not([placeholder='']) {
  // Keeps placeholders from being cut off
  width: 100% !important;
}

.select2-selection__choice {
  max-width: 95%;
  /** Prevent individual select2 tags from overflowing or cutting off */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: auto;
}

.select2-container--bootstrap {
  .select2-results__group {
    color: variables.$gray-50;
  }
  .select2-selection__placeholder {
    font-size: 14px;
  }
}

.select2-results__group {
  background-color: variables.$gray-5;
}

#select2-talent_pool_job_function_category_id-results,
#select2-search_client_company_attributes_industry_category_ids-results,
#select2-search_job_function_category_id-results {
  .select2-results__option[aria-disabled='true'] {
    background: variables.$white;
    border-bottom: 1px solid variables.$gray-20;
    border-top: 1px solid variables.$gray-20;
    color: variables.$gray-60;
    font-weight: variables.$font-weight-bold;
    margin-left: 6px;
    padding-left: 4px;
  }
}

.select2-container--bootstrap
  .select2-results__option--highlighted[aria-selected] {
  background-color: variables.$light-blue;
  color: variables.$gray-50;
}

.select2-dropdown {
  z-index: $z-index-select2-dropdown !important;
}

.has-error .select2-container .select2-selection {
  // Set the correct border color for select2's when they are in an error state.
  border-color: $state-danger-text;
}

.select2-container--default.select2-container--focus
  .select2-selection--multiple,
.select2-container .select2-selection--single {
  min-height: variables.$input-height-base !important;
}

.select2-container--default .select2-selection--single {
  border-color: variables.$input-border-color;
  box-shadow: variables.$input-box-shadow;
}

.select2-container--default .select2-selection--multiple {
  border: 1px solid variables.$input-border-color;
  box-shadow: inset variables.$input-box-shadow;
  min-height: variables.$input-height-base;
}

.select2-container--default
  .select2-selection--multiple
  .select2-selection__rendered {
  padding-left: variables.$space-4;
  padding-top: variables.$space-4;
}

.select2-container--default.select2-container--focus
  .select2-selection--multiple {
  border: 1px solid variables.$input-border-color;
}

.select2-search__field {
  padding-left: variables.$space-8 !important;
  width: 100% !important;
}

// Hard-code the dropdown arrow to render like the 4.0 select menu caret.
// Arrow wrapper element
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 100%;
  right: variables.$space-12;
  width: variables.$space-16;

  // The arrow element
  b {
    background-image: url('~assets/images/chevron-down-4.0.svg');
    background-position: 0 center;
    background-repeat: no-repeat;
    border: none;
    height: 100%;
    left: auto;
    margin-left: unset;
    top: auto;
    width: variables.$space-16;
  }
}
