@use '@thrivetrm/ui/stylesheets/variables';

//
// Wells
// --------------------------------------------------

// Base class
.well {
  background-color: $well-bg;
  border: 1px solid $well-border;
  border-radius: variables.$border-radius-base;
  box-shadow: inset 0 1px 1px rgba(variables.$black-shadow, 0.05);
  margin-bottom: 20px;
  min-height: 20px;
  padding: 19px;
  blockquote {
    border-color: #dddddd;
  }
}

// Sizes
.well-lg {
  border-radius: variables.$border-radius-large;
  padding: 24px;
}
.well-sm {
  border-radius: variables.$border-radius-small;
  padding: 9px;
}
