@use '@thrivetrm/ui/stylesheets/variables';

// Global layout styles
// Misc global styles that should be moved into more appropriate files

html,
body {
  height: 100%;
}

#main {
  position: relative;
}

// Hack to allow for a footer that anchors to the bottom of the viewport when the
// page content is not tall enough to fill the viewport.
#wrap {
  margin-bottom: -$footer-total-height;
  min-height: 100%;

  &:after {
    content: '';
    display: block;
    height: $footer-total-height;
  }
}

#content {
  margin-top: 1em;
}

.full-page-takeover {
  background-color: variables.$gray-5;
}
.container-full-width {
  padding-left: variables.$space-24;
  padding-right: variables.$space-24;
}

.empty-message {
  color: variables.$gray-40;
  font-style: italic;
}

body.modal-open {
  overflow: scroll;
  padding-right: 0 !important;
  position: absolute;
  width: 100%;
}

.contact-section {
  > h2 > a > .fa {
    font-size: 14px;
    margin-left: 0.5em;
  }
}

th > i.indicator {
  display: none;
}

.message-container {
  position: relative;
  z-index: $z-index-message-container;

  .alert {
    margin: 0.5em;
  }
}

// Due to the awkward layout of the search detail page, the alerts need default horizontal margin
// to look nice as an overlay on the page. For full-width pages we can stretch all the way out.
.container-full-width .message-container .alert {
  margin-left: 0;
  margin-right: 0;
}

/**
 * Hide Safari's contacts auto-fill button on any inputs
 */
input::-webkit-contacts-auto-fill-button {
  display: none !important;
  pointer-events: none;
  position: absolute;
  right: 0;
  visibility: hidden;
}

// The wootric textbox cannot be accessed when a modal is open, so give a z-index to the wootric modal so we can open our true app's modal at the top of the screen
#wootric-modal {
  z-index: $z-index-wootric-modal !important;
}
