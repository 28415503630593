@use '@thrivetrm/ui/stylesheets/variables';

.datepicker {
  // Undo base table styles that do not apply to datepicker's calendar table
  table {
    border: none;
    box-shadow: none;
  }

  tr > th:first-child,
  tr > td:first-child {
    padding-left: variables.$space-8;
  }

  tr > th:last-child,
  tr > td:last-child {
    padding-right: variables.$space-8;
  }
}
